<template>
  <section id="wrapper">
    <router-view />
  </section>
</template>
<script>
export default {
  
}
</script>
