import _ from 'lodash'

const menu = [
  {
    title: 'Dashboard',
    route: {name: 'BoDashboard'},
    menu: 'BoDashboard',
    icon:  'icon-speedometer',
  },
  {
    cap: 'Website Management',
    relate: [
      'BoHome',
      'BoProducts',
      'BoInvestorRelations',
      'BoInvRelate',
      'InvRelateCompanyInfo',
      'InvRelateReport',
      'InvRelateGovernance',
      'BoShareInformation',
      'ArticleCat',
      'ServicePayPremi',
      'ServiceComplain',
      'ServiceClaimInfo',
      'ArticleList',
      'ArticleCategory',
      'BoCsr',
      'BoContact',
      'BoOffice',
      'BoCareer',
      'BoApplicants',
      'BoTermCondition',
      'BoPrivacyPolice',
      'BoWebSettings',
      'BoCalculatorData',
      'StaticSEO',
    ]
  },
  {
    title: 'Home',
    route: {name: 'BoHome'},
    icon:  'ti-home',
    menu: 'BoHome'
  },
  {
    title: 'Products',
    route: {name: 'BoProducts'},
    icon: 'icon-grid',
    menu: 'BoProducts'
  },
  {
    title: 'Investor Relations',
    route: { name: 'BoInvestorRelations' },
    icon: 'ti-bookmark-alt',
     children: [
      { title: 'Investor Relation Page', route: { name: 'BoInvRelate' }, menu:'BoInvRelate' },      
      { title: 'Company Information', route: { name: 'InvRelateCompanyInfo' }, menu:'InvRelateCompanyInfo' },
      { title: 'Company Report', route: { name: 'InvRelateReport' }, menu:'InvRelateReport' },
      { title: 'Company Governance', route: { name: 'InvRelateGovernance' }, menu:'InvRelateGovernance' },
      {
        title: 'Stock Information',
        route: {
          name: 'BoShareInformation'
        },
        menu :'BoShareInformation'
      },
      {
        title: 'RUPS Information',
        route: {
          name: 'BoInfo'
        },
        menu: 'BoInfo'
      },
    ],
  },
  {
    title: 'Services',
    icon:  'icon-layers',
    children: [
      {
        title: 'Claim Information',
        route: {
          name: 'ServiceClaimInfo'
        },
        menu: 'ServiceClaimInfo'
      },
      {
        title: 'Premi Payment',
        route: {
          name: 'ServicePayPremi'
        },
        menu: 'ServicePayPremi'
      },
      {
        title: 'Calculator',
        route: {
          name: 'BoCalculator'
        },
        menu: 'BoCalculator'
      },
      {
        title: 'Complain',
        route: {
          name: 'ServiceComplain'
        },
        menu: 'ServiceComplain'
      },
    ]
  },
  {
    title: 'Blog',
    icon: 'ti-layout-list-thumb',
    children: [
      {
        title: 'Blog List',
        route: {
          name: 'ArticleList'
        },
        menu: 'ArticleList'
      },
      {
        title: 'Blog Category',
        route: {
          name: 'ArticleCategory'
        },
        menu: 'ArticleCategory'
      },
    ],
  },
  {
    title: 'CSR',
    icon: 'icon-people',
    route: {
      name: 'BoCsr'
    },
    menu: 'BoCsr'
  },
  {
    title: 'Contact Us',
    icon:  'icon-paper-plane',
    children: [
      {
        title: 'Contact Page',
        route: {name: 'BoContact'},
        menu: 'BoContact'
      },
      {
        title: 'Office',
        route: {name: 'BoOffice'},
        menu: 'BoOffice'      
      },
      {
        title: 'Premium Calculator Data',
        route: {
          name: 'BoCalculatorData'
        },
        menu: 'BoCalculatorData'
      },
    ]
  },
  {
    title: 'Career',
    icon:  'icon-briefcase',
    children: [
      {
        title: 'Career Page',
        route: { name: 'BoCareer' },
        menu: 'BoCareer'
        
      },
      {
        title: 'Applicants',
        route: { name: 'BoApplicants' },
        menu: 'BoApplicants'
      },
    ]
  },
  {
    title: 'Legal',
    icon: 'icon-docs',
    children: [{
        title: 'Terms and Conditions',
        route: {
          name: 'BoTermCondition',
          params: {
            pageSlug: 'terms-and-conditions'
          }
        },
        menu: 'BoTermCondition',

      },
      {
        title: 'Privacy Policy',
        route: {
          name: 'BoPrivacyPolice',
          params: {
            pageSlug: 'privacy-policy'
          }
        },
        menu: 'BoPrivacyPolice',

      },
    ]
  },
  {
    title: 'Web Settings',
    icon: 'icon-settings',
    route: { name: 'BoWebSettings' },
    menu: 'BoWebSettings'
  },
  {
    title: 'Static SEO',
    icon: 'ti-receipt',
    route: {
      name: 'StaticSEO'
    },
    menu: 'StaticSEO'
  },
  {
    cap: 'CMS Settings',
    relate: [
      'Users',
      'BoUserLevel'
    ]
  },
  {
    title: 'User Management',
    icon: 'icon-user-follow',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users'
      },
      {
        title: 'User Level',
        route: {name: 'BoUserLevel'},
        menu: 'BoUserLevel'
      },
    ],
  },
  // {
  //   title: 'AuditTrail',
  //   route: {
  //     name: 'AuditTrail'
  //   },
  //   icon: 'icon-check',
  //   menu: 'AuditTrail'
  // },
]

let nav = menu

const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      })
    }

    return v
  })
}

export default nav