<template>
  <ul id="sidebarnav">
    <component
      v-for="(nav, key) in items"
      :is="resolveNavComp(nav)"
      :key="key"
      :item="nav"
    />
  </ul>
</template>

<script>
import BoNavLink from '@/components/backend/navigation/BoNavLink.vue'
import BoNavCap from '@/components/backend/navigation/BoNavCap.vue'
import BoNavGroup from '@/components/backend/navigation/BoNavGroup.vue'
import navUtils from '@/navigation/navUtils'

export default {
  components: {
    BoNavLink, BoNavCap, BoNavGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  methods: {
    resolveNavComp(item) {
      return navUtils.resolveNavComp(item)
    }
  }
}
</script>
